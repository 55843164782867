import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/en'


const Card = () => externalComponent('card')
const CardIcon = () => externalComponent('cardIcon')
const CardButton = () => externalComponent('cardButton')
const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination')

export default {
  name: 'Stay',

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    DatePicker
  },

  data() {
    return {
      highlighted: [new Date(2021, 8, 24), new Date(2021, 8, 29)],

      info: {
        title: 'Calendario de reservas',
        status: {},
        pending: '',
        sent: '',
        blocked: '',
        paid: ''
      },

      status_count: {
        pending: 0,
        paid: 0,
        sent: 0,
        canceled: 0,
        expired: 0,
        blocked: 0
      },

      ids: [],

      timeline: {
        action: {
          name: 'Acciones',
          options: [{
            name: 'Agregar Servicio',
            value: 'addService'
          }]
        },
        search: {
          name: 'Calendar',
          placeholder: 'Calendar'
        },
        filter: {
          name: 'Estado',
          options: [
            {
              name: 'Aprobada',
              value: 'open'
            },
            {
              name: 'Denegada',
              value: 'close'
            },
            {
              name: 'Revisión',
              value: 'pending'
            },
          ]
        },
        sections: [
          {
            name: 'Cargos',
            value: 'charges'
          },
          {
            name: 'Ordenes de pago',
            value: 'orders'
          }
        ],
        section: 'charges',
        columns: [
          {
            name: '#Movimiento',
            checkbox: true,
          },
          {
            name: 'Colaborador'
          },
          {
            name: 'Monto Base'
          },
          {
            name: 'Fecha'
          },
          {
            name: 'Estado'
          }
        ],
        rows: [],
        pages: 0,
        currentPage: 1
      },

      calendar: {
        reserved: [],
        selected: "",
        msg: '',
        class: 'available',
        daysOfMonth: []
      },

      isBlocked: false,
      isAvailable: false,
      isModalCreateService: false,
      isModalCreateOrder: false
    }
  },

  methods: {
    getDaysOfMonth: async function(e = null) {
      let month = null;
      if(e === null)
        month = new Date().getMonth();
      else
        month = e.getMonth();
      
      await axios
      .get(apiDomain + "/calendar/month/" + month)
      .then(res => {
        this.calendar.daysOfMonth = res.data;
        this.status_count = {
          pending: 0,
          paid: 0,
          sent: 0,
          canceled: 0,
          expired: 0,
          blocked: 0
        };
        this.calendar.daysOfMonth.find( v => {
          if( v.status === 'pending' )
            this.status_count.pending++;
          if( v.status === 'paid' )
            this.status_count.paid++;   
          if( v.status === 'sent' )
            this.status_count.sent++;   
          if( v.status === 'canceled' )
            this.status_count.canceled++
          if( v.status === 'expired' )
            this.status_count.expired++;
          if( v.status === 'blocked' )
            this.status_count.blocked++;
        } );
      })
      .catch();
    },

    getClasses(date = null) {
      let res = '';
      date = date.toISOString().split('T')[0];

      this.calendar.daysOfMonth.find( v => {
        if( new Date(v.date).toISOString().split('T')[0] === date ) {
          if( v.status === 'pending' )
            {    res = "highlight highlight--pending"; }
          if( v.status === 'paid' )
            {       res = "highlight highlight--paid"; }
          if( v.status === 'sent' )
            {       res = "highlight highlight--sent"; }
          if( v.status === 'canceled' )
            {   res = "highlight highlight--canceled"; }
          if( v.status === 'expired' )
            {    res = "highlight highlight--expired"; }
          if( v.status === 'blocked' )
            {    res = "highlight highlight--blocked"; }
        }
      } );

      return res;
    },

    getStay: async function() {
      return
      let response;
      let id = this.$route.params.id;

      await axios
        .get(apiDomain + '/commercial/stay/detail/' + id)
        .then(res => response = res)
        .catch(err => console.log(err));

      if (typeof response !== 'undefined' && response.status === 200) {
        this.info = response.data.info;
        this.timeline.rows = response.data.timeline;
      } else {
        this.$toast.error('Error en la consulta de la estancia');
      }
    },

    doAction: function(action) {
      switch (action) {
        case 'addService':
          this.showModalCreateService();
          break;
      }
    },

    extra: function(id) {
    },

    getDay: async function() {
      await axios
      .get(apiDomain + "/calendar/" + this.calendar.selected)
      .then(res => {
        this.isAvailable = false;
        this.isBlocked = false;
        if(res.data.length) {
          if( res.data[0].status === 'blocked' ) {
            this.isAvailable = true;
            this.isBlocked = true;
          }
        } else {
          this.isAvailable  = true;
        }
      })
      .catch();
    },

    changeBlockedStatus: async function() {
      let response
      await axios
      .get(apiDomain + "/calendar/blocked/" + this.calendar.selected)
      .then(res => response = res).catch(err => response = err);

      this.getDaysOfMonth();
      this.getDay();
    },

    showModalCreateService: function() {
      this.isModalCreateService = true;
    },

    hideModalCreateService: function() {
      this.isModalCreateService = false;
    },

    showModalCreateOrder: function() {
      this.isModalCreateOrder = true;
    },

    hideModalCreateOrder: function() {
      this.isModalCreateOrder = false;
    }
  },

  mounted() {
    this.calendar.selected = new Date().toISOString().split('T')[0].replaceAll('-', '');
    this.getDaysOfMonth();
    this.getDay();

    EventBus.$on('hideModalCreateService', () => {
      this.hideModalCreateService();
    });

    EventBus.$on('hideModalCreateOrder', () => {
      this.hideModalCreateOrder();
    });
  },

  watch: {
    'calendar.selected': function(val) {
      this.getDay();
    }
  }
}
